<template>
    <vx-card title="Simple Stock Transfer Order Create">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Warehouse></Warehouse>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Warehouse></Warehouse>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Req. Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Est. Time Arrived</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="description" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="30%">SKU Code</th>
                            <th width="30%">SKU Desc.</th>
                            <th width="20%">HU</th>
                            <th width="15%">Qty</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom;: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select :filterable="false" :options="skuLines[index].option_sku"
                                    v-model="skuLines[index].selected_sku" @input="(val) => { onChangeSku(val, index); }"
                                    @search="(search, loading) => { onSearchSku(search, loading, index); }" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :readonly="true" class="w-full"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select :filterable="false" :options="skuLines[index].option_hu"
                                    v-model="skuLines[index].selected_hu" @input="(val) => { onChangeHu(val, index); }" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px"><vs-input type="number"
                                    v-model="skuLines[index].qty"></vs-input></td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="vx-input-group flex">
                                    <vs-button v-if="skuLines.length > 1" @click.stop="removeRow(index)" size="small"
                                        color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                        title="Remove Row" />
                                    <vs-button v-if="index == skuLines.length - 1" @click.stop="addRow()" size="small"
                                        color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";

import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Date,
        vSelect
    },
    data() {
        return {
            readonly: true,
            code: null,
            description: null,
            skuLines: [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                }
            ]
        }
    },
    methods: {
        handleBack() {
            this.$router.push({
                name: "simple-sto",
            });
        },

        handleChangeAttachment() {

        },
        handleSubmit() {

        },
        onChangeSku(val, index) {

        },

        onSearchSku(search, loading, index) {

        },
        onChangeHu(val, index) {

        },
        removeRow(index) {
            this.skuLines.splice(index, 1);
        },
        addRow() {
            this.skuLines.push({
                option_sku: [],
                selected_sku: null,
                option_hu: [],
                selected_hu: null,
                qty: 0,
            });

        }
    }
}
</script>